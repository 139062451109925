import $ from "jquery";
import "bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
import "./styles.scss";

$(function() {
  "use strict";
});
